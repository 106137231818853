// Generic imports
import React, { useState, useEffect } from "react"
import { toast } from "react-toastify"

// Other components imports
import Layout from "components/layout"
import SEO from "components/seo"
import Input from "components/Reusable/Input"
import TextArea from "components/Reusable/Input/textarea"
import Button from "components/Button"
import MustLoggedInWrapper from "components/Reusable/MustLoggedInWrapper"
import { useFormik } from "formik"

// Redux imports

// Static imports

// Utils imports
import { getTutorMe, becomeATutor, updateTutor } from "services/kelas"

const TutorDashboardPage = () => {
  const [loading, setLoading] = useState(false)
  const [isTutor, setIsTutor] = useState(false)
  const [isEditing, setIsEditing] = useState(false)

  const formik = useFormik({
    initialValues: {
      description: "",
      portofolio_link: "",
      role: "",
    },
    onSubmit: values => {
      setLoading(true)
      if (isTutor) {
        updateTutor(values)
          .then(() => {
            toast.success("Tutor profile updated")
          })
          .catch(() => {
            toast.error("An error occured. Please try again later")
          })
          .finally(() => {
            setLoading(false)
          })
      } else {
        becomeATutor(values)
          .then(resp => {
            toast.success("Tutor registration successful")
            setIsTutor(true)
            formik.setValues(resp.data)
          })
          .catch(() => {
            toast.error("Tutor registration failed")
          })
          .finally(() => {
            setLoading(false)
          })
      }
    },
  })

  useEffect(() => {
    getTutorMe().then(resp => {
      setIsTutor(true)
      formik.setValues(resp.data)
    })
  }, [])

  return (
    <Layout>
      <MustLoggedInWrapper>
        <SEO title="Tutor Dashboard" />
        <div className="layout">
          {isTutor && (
            <div style={{ paddingTop: "20px", textAlign: "center" }}>
              <div style={{ paddingBottom: "10px" }}>
                Anda telah registrasi sebagai Tutor di Taman Siswa
              </div>
              <Button
                to="/create-class"
                className="secondary"
                style={{ margin: "5px" }}
              >
                Buat Kelas
              </Button>
              <Button
                to="/dashboard"
                className="secondary"
                style={{ margin: "5px" }}
              >
                Buka Dashboard
              </Button>
              <hr />
            </div>
          )}
          {(isTutor && isEditing) || !isTutor ? (
            <form onSubmit={formik.handleSubmit}>
              <br />
              <h2 style={{ textAlign: "center" }}>
                {isTutor ? "Edit Profil Tutor" : "Daftar Menjadi Tutor"}
              </h2>
              <TextArea
                name="description"
                label="Description"
                type="text"
                placeholder="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla tempus lacus risus, quis ultrices quam feugiat tempor. Pellentesque vel mauris pharetra, interdum ex ac, porta lacus."
                required
                onChange={formik.handleChange}
                value={formik.values.description}
              />
              <Input
                name="portofolio_link"
                label="Link Portofolio"
                placeholder="https://bit.ly/cv_taman_siswa"
                type="text"
                required
                onChange={formik.handleChange}
                value={formik.values.portofolio_link}
              />
              <div style={{ paddingTop: "8px" }}>
                <Input
                  name="role"
                  label="Role"
                  type="text"
                  placeholder="UI UX Engineer at Bukabuka"
                  required
                  onChange={formik.handleChange}
                  value={formik.values.role}
                />
              </div>
              <div
                style={{
                  paddingTop: "24px",
                  paddingBottom: "24px",
                  textAlign: "center",
                }}
              >
                {isTutor ? (
                  <Button
                    onClick={() => setIsEditing(false)}
                    type="button"
                    style={{ margin: "5px" }}
                    className="secondary"
                  >
                    Cancel
                  </Button>
                ) : (
                  <></>
                )}
                <Button disabled={loading} style={{ margin: "5px" }}>
                  Submit Form
                </Button>
              </div>
            </form>
          ) : (
            <div style={{ textAlign: "center", paddingBottom: "20px" }}>
              <Button onClick={() => setIsEditing(true)}>
                Edit Profil Tutor
              </Button>
            </div>
          )}
        </div>
      </MustLoggedInWrapper>
    </Layout>
  )
}

export default TutorDashboardPage
